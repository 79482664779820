import * as React from "react";
import { graphql } from "gatsby";
import { InlineMath } from "react-katex";

import RegularNote from "../../src/components/note/RegularNote";
import CitationNote from "../../src/components/note/CitationNote";
import { StaticImage } from "gatsby-plugin-image";
import wrapWithPostTemplate from "../../src/templates/post";
import Code from "../../src/components/code";
import Note from "../../src/components/note/Note";

export const frontmatter = {
  title: "Using OpenCV to solve a sudoku",
  subtitle: "A small Python script that solves sudoku from images",
  meta: "Python • Posted December 5, 2020",
  author: "hello@golsteyn.com",
  category: "computer-vision",
  date: "2020-12-05T00:00:00.000Z",
};

const Sudoku = () => (
  <>
    <p>
      I recently acquired a pretty fun new hobby, <b>solving sudokus</b>. In
      that process, I discovered a new YouTube channel,{" "}
      <a href="https://www.youtube.com/channel/UCC-UOdK8-mIjxBQm_ot1T-Q">
        Cracking the Cryptic
      </a>
      , which is releasing two new sudoku puzzles every day. I have been
      diligently attempting these new puzzles and practicing easier puzzles on a
      sudoku book I have at home.
    </p>
    <p>
      While struggling to solve another sudoku (rated as "easy" which is
      probably incorrect), I thought of another computer vision project:{" "}
      <b>could my computer solve sudoku from a picture of one?</b>
    </p>
    <figure className="full raise">
      <StaticImage
        src="../image/sudoku/sudoku_solved.png"
        alt="Solving the sudoku and printing the solution in the original image."
      />
      <figcaption>
        Solving the sudoku and printing the solution in the original image.
      </figcaption>
    </figure>
    <p>
      I want to be able to recognize the sudoku in an image, adjust for
      perspective, identify the numbers in the grid, and solve the sudoku. I
      then want to print the solution back into the image,{" "}
      <b>adjusting for perspective.</b> This article will cover the algorithm I
      created to process images of sudokus. It consists of the following steps:
    </p>
    <ol>
      <li>Find the sudoku grid in an image</li>
      <li>
        Perform a perspective warp to create a top-down view of the sudoku
      </li>
      <li>Split the sudoku image into its cells</li>
      <li>Identify the number in each cell (or lack thereof)</li>
      <li>Identify the row and column number for each cell</li>
      <li>Solve the sudoku</li>
      <li>Write the result in the original image, adjusting for perspective</li>
    </ol>
    <h2>Getting started</h2>
    <p>
      This project will require OpenCV, sklearn, and numpy installed on your
      machine.
    </p>
    <Code language="shell" source={`pip install opencv-python numpy sklearn`} />
    <p>Let's start by importing our dependencies:</p>
    <Code
      language="python"
      source={`
import cv2
import numpy as np
import glob
import os
`.trim()}
    />
    <p>
      <RegularNote
        content={
          <>
            All images are taken from one sudoku book. If you use this code on
            any other sudoku grid, you may need to adjust some parameters.
          </>
        }
      >
        I will be processing images in a folder.
      </RegularNote>{" "}
      I process images one by one, first resizing them to a more manageable size
      to help with processing. The code below will form the main loop of this
      image processing task. I will be processing images in a folder.
    </p>
    <figure className="full">
      <Code
        language="python"
        source={`
if __name__ == "__main__":
for file_path in glob.glob("in/*.jpg"):
    img = cv2.imread(file_path)

    # Ensure we keep the aspect ratio of the image
    ratio = img.shape[0] / img.shape[1]
    img = cv2.resize(img, (1100, int(1100 * ratio)))

    valid, img_grid, M = find_grid(img)

    # If no grid were found, give up
    if valid == False:
        continue

    # Generate a 2D array representation of the grid present in the image
    cells = find_cells(img_grid)
    cells_with_value = find_cell_values(cells)
    grid, grid_meta = get_sudoku_grid(cells_with_value)

    # Solve the sudoku
    empty_cells = [(i, j) for i in range(9) for j in range(9) if grid[i][j] == 0]
    is_grid_solved = solve(grid, empty_cells)

    # Print the solution back in the image
    if is_grid_solved:
        img_out = print_solution(img, img_grid, M, grid, grid_meta)
        cv2.imwrite(f"out/{os.path.split(file_path)[-1]}", img_out)`.trim()}
      />
      <figcaption>The main code loop</figcaption>
    </figure>
    <h2>Finding the sudoku grid in an image</h2>
    <p>
      I am looking for a <b>large square consisting of 81 smaller squares.</b>{" "}
      This square can be taken from any perspective. In other words, I am
      looking for a quadrilateral in an image.
    </p>
    <figure className="full raise">
      <StaticImage
        src="../image/sudoku/sudoku-examples.png"
        alt="Example of sudoku grids from various perspective."
      />
      <figcaption>Example of sudoku grids from various perspective.</figcaption>
    </figure>
    <h3>Looking for quadrilaterals</h3>
    <p>
      I will be using OpenCV contours to find quadrilaterals. After thresholding
      the image (using adaptive thresholding), I can generate a list of contours
      present in the image. I then need to filter these contours to only those
      that closely resemble a quadrilateral.
    </p>
    <p>
      <CitationNote
        sourceId="cv2-contour-methods"
        content={
          <a href="https://opencv-python-tutroals.readthedocs.io/en/latest/py_tutorials/py_imgproc/py_contours/py_contour_features/py_contour_features.html">
            OpenCV contour methods
          </a>
        }
      >
        <b>
          <code>cv2.arcLength</code> and <code>cv2.approxPolyDP</code> are used
          to detect quadrilaterals.
        </b>
      </CitationNote>{" "}
      <code>cv2.arcLength</code> returns the length of the perimeter of a
      contour while{" "}
      <RegularNote
        content={
          <>
            <figure>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/30/Douglas-Peucker_animated.gif"
                loading="lazy"
                alt="An example of the Ramer–Douglas–Peucker algorithm"
                style={{ width: "100%" }}
              />
            </figure>
            <br />
            <code>cv2.approxPolyDP</code> uses the{" "}
            <b>Ramer–Douglas–Peucker algorithm</b>, a recursive algorithm that
            performs a curve simplification by returning a similar curve with
            fewer points.{" "}
            <a href="https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm">
              Wikipedia
            </a>
          </>
        }
      >
        <code>cv2.approxPolyDP</code>
      </RegularNote>{" "}
      returns a contour forming an approximation of the contour passed as
      argument. The approximation is controlled by a parameter,{" "}
      <InlineMath math="\epsilon" />, which defines the maximum variance between
      the perimeter of the approximation and the original contour.{" "}
      <code>cv2.approxPolyDP</code> is useful when trying to find contours that
      approximates a quadrilateral. If the approximate contour has four points
      while <InlineMath math="\epsilon" /> is kept to a low value (here{" "}
      <InlineMath math="1\%" /> of the perimeter of the original contour), I
      know I have a quadrilateral.
    </p>
    <p>
      To verify if I found the contour of the sudoku grid (and not the contour
      of a cell of the grid for example),{" "}
      <b>I check that the contour contains 81 smaller quadrilaterals.</b> If
      this is the case, it is very likely that I found the sudoku grid.
    </p>
    <figure className="full">
      <Code
        language="python"
        source={`# Approximate the contour in order to determine whether the contour is a quadrilateral
peri = cv2.arcLength(c, True)
approx = cv2.approxPolyDP(c, 0.01 * peri, True)

# We are looking for a contour that is roughly a quadrilateral
if len(approx) == 4:
    warped, M = four_point_transform(
        thresh,
        np.array([approx[0][0], approx[1][0], approx[2][0], approx[3][0]]),
    )

    cells = find_cells(warped)

    # We can be fairly certain we found a sudoku grid if the grid contains 81 cells
    if len(cells) == 81:
        return True, warped, M`}
      />
      <figcaption>The method to locate a sudoku grid</figcaption>
    </figure>
    <h3>Perspective correction</h3>
    <p>
      <CitationNote
        sourceId="homography"
        content={
          <a href="https://docs.opencv.org/master/d9/dab/tutorial_homography.html">
            Basic concepts of the homography explained with code
          </a>
        }
      >
        <b>
          Two images of the same planar surface are related by a homography.
        </b>
      </CitationNote>{" "}
      If we can determine the plane of the sudoku grid, we can create a new
      image where the grid appears directly below the camera (ie. on the image
      plane). This process is also known as perspective removal.
    </p>
    <p>
      To find a homography matrix, I need to find four corresponding points
      shared between the two images. I already have four points from our initial
      image when I located the sudoku grid. The corresponding points in the
      perspective corrected image are the four corners of the image.
    </p>
    <p>
      OpenCV allows us to calculate a homography matrix and apply the
      perspective correction to an image.
    </p>
    <figure className="full">
      <Code
        language="python"
        source={`# rect contains the four points of the sudoku grid square in the original image
# dst contains the four points representing  the four corners of the warped image

M = cv2.getPerspectiveTransform(rect, dst)
warped = cv2.warpPerspective(img, M, (max_width + 10, max_height + 10))`}
      />
      <figcaption>
        Using OpenCV to find the homography matrix and apply it to the image.
      </figcaption>
    </figure>
    <figure className="full">
      <StaticImage src="../image/sudoku/sudoku-warped-example.png" />
      <figcaption>
        Before and after applying thresholding and perspective removal.
      </figcaption>
    </figure>
    <h3>Extracting each cell in the grid</h3>
    <p>
      I need to extract each cell from the warped image to read their value when
      creating a 2D array of the sudoku grid. I apply the same methods that I
      used to find the sudoku grid.
    </p>
    <h2>Representing the sudoku grid as a 2D array</h2>
    <h3>Retrieving the number in each cell</h3>
    <p>
      I have an image of all the 81 cells of the sudoku, corrected for
      perspective. Now I need to extract the number printed in each cell (if it
      exists). For this, I trained a <b>k-nearest neighbours classifier</b> on a
      subset of the cell images taken from the same sudoku book.
    </p>
    <p>
      Each cell image was resized to a <InlineMath math="28*28" /> matrix and
      flattenned into a row of our example matrix <InlineMath math="X" />. I
      labeled each cell image with the number in the cell, or{" "}
      <InlineMath math="0" /> if the cell was empty.
    </p>
    <figure className="raise">
      <video autoPlay muted loop>
        <source src="/train_sudoku.mp4" type="video/mp4" />
      </video>
      <figcaption>
        Demonstrating of the training process. For each cell image, I press the
        corresponding number on my keyboard. These data, alongside the cell
        image itself, are used for training the classifier.
      </figcaption>
    </figure>
    <p>
      I selected <InlineMath>k=5</InlineMath> for the classifier. With a dataset
      of 1008 sudoku cells, with a <InlineMath>70:30</InlineMath>split between
      the training and test dataset, the model achieved{" "}
      <InlineMath>100\%</InlineMath> accuracy on the training and testing data.
      This high accuracy is a result of the model being trained on cells coming
      from the same sudoku book, leaving very little variance between each
      example.
    </p>
    <h3>Determining the row and column of each cell</h3>
    <p>
      <Note
        content={
          <figure>
            <img
              src="/sort-sudoku-row.gif"
              alt="Visualizing the algorithm assigning a row to each cell"
              loading="lazy"
              style={{ width: "100%" }}
            />
            <figcaption>
              Visualizing the algorithm assigning a row to each cell in a 5 by 5
              grid.
              <b>
                The number in each cell represents the y-coordinate of the cell.
              </b>{" "}
              Here, each cell has a small offset in the y-direction. Unique
              colors are assigned to each row.
            </figcaption>
          </figure>
        }
      />
      I can determine which row and column each cell belongs to through a simple
      sort. Sorting cells by their <InlineMath>x</InlineMath> coordinate, the
      first nine cells are part of the first row, the next nine part of the
      second row, and so on. The same logic applies to the{" "}
      <InlineMath>y</InlineMath> values.
    </p>
    <p>
      This logic works as I adjusted the sudoku grid for perspective in an
      earlier step. Hence, I assume that rows will remain roughly horizontal and
      columns roughly vertical.
    </p>
    <h2>Solving the sudoku</h2>
    <p>
      I used a backtracking algorithm to solve the sudoku. After retrieving a
      list of all empty cells of the sudoku grid, I test a number for each empty
      cell one by one. I first verify that the number is a valid move for this
      cell. If so, I use recursion to solve the sudoku given the guess I made
      for that initial empty cell. If no number is valid for a particular empty
      cell, I backtrack, testing a new value for the previous empty cell.{" "}
      <a href="https://medium.com/swlh/simple-sudoku-with-backtracking-bb4813ddabb1">
        This article
      </a>{" "}
      provides a more thorough explanation of this process.
    </p>
    <figure className="full">
      <Code
        language="python"
        source={`def solve(sudoku, empty_cells):
  """
  Create a solved sudoku grid using backtracking and recursion.
  Inspired from https://stackoverflow.com/a/58139570/2034508
  """
  if len(empty_cells) > 0:
      # Work on the first empty cell
      empty = empty_cells[0]
      (i, j) = empty

      # Test for a solution with numbers from 1 to 9
      for num in range(1, 10):
          # Skip invalid moves
          if not correct(num, j, i, sudoku):
              continue

          # If move is valid, set it, and solve the sudoku from there
          sudoku[i, j] = num
          if solve(sudoku, empty_cells[1:]):
              return True
          # If we reach this point, we could not solve the sudoku with that move
          # Reset, and try a different number
          sudoku[i, j] = 0
      
      # If we reach this point, we could not solve the sudoku with any number for that cell
      # Backtrack
      return False
  else:
      # If empty cell array is empty, we are done!
      return True`}
      />
      <figcaption>
        Using backtracking and recursion to solve sudoku represented as a 2D
        array.
      </figcaption>
    </figure>
    <p>
      <Note
        content={
          <figure className="raise">
            <video autoPlay muted loop>
              <source src="/sudoku.mp4" type="video/mp4" />
            </video>
            <figcaption>Solving a sudoku, visualized.</figcaption>
          </figure>
        }
      />
      Backtracking algorithms are usually equivalent to brute-force methods as
      most don't adopt any efficient heuristics. The algorithm I used is no
      different. However, for this project, it proved to be a relatively simple
      solution to solving sudoku.
    </p>

    <h2>Wrapping up</h2>
    <p>
      With the sudoku solved, I can then print the values of the solved grid
      back into the perspective corrected image. I then warp the solved sudoku
      image back into its original perspective using the inverse of the
      homography matrix found in the initial step.
    </p>
    <figure className="raise hide-tablet">
      <video
        autoPlay
        muted
        loop
        style={{ display: "block", maxWidth: 300, margin: "0 auto" }}
      >
        <source src="/sudoku.mp4" type="video/mp4" />
      </video>
      <figcaption>Solving a sudoku, visualized.</figcaption>
    </figure>
    <p>
      You can find all the code for this small project in this{" "}
      <a href="https://gist.github.com/qgolsteyn/7da376ced650a2894c2432b131485f5d">
        Gist
      </a>
      !
    </p>
  </>
);

export const query = graphql`
  query($id: String) {
    javascriptFrontmatter(id: { eq: $id }) {
      frontmatter {
        author {
          email
          firstName
          name
        }
        category {
          name
        }
        meta
        subtitle
        title
        date
      }
    }
  }
`;

export default wrapWithPostTemplate(
  Sudoku,
  <img src="/image/header/sudoku.svg" alt="" className="hero_image" />
);
